import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Button, IconButton, Loader, Toast, Alert } from '@refera/ui-web'
import * as Modal from '_components/modal/generic-modal'
import { AddCircle as AddCircleIcon, MinusCirlce as MinusCircleIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Grid } from '@mui/material'
import { Typography, TextField, Select, MenuItem, FormControl } from '@material-ui/core'
import CommonSelect from '_components/common/select'
import { ControlledSwitch } from '_components/inputs'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import {
  getProviderSuggestionsListSelector,
  getProviderSuggestionsListSelectorLoading,
  providerSuggestionsSelector,
  providersResultWithoutPaginationSelector,
} from '_modules/provider/selectors'
import { getProvidersWithoutPagination } from '_modules/provider/actions'
import { REGISTER_STATUS } from '_utils/constants'
import { reorder } from '_utils/helpers'
import DragAndDrop from '_components/common/drag-and-drop'

import useStyles from './styles'
import { createTradesmanSequence } from '_modules/tradesman-sequence/actions'
import { getServiceOrder, getGenericParameters } from '_modules/service-orders/actions'
import Loading from '_/components/loading'
import { GENERIC_PARAMETERS } from '_/utils/constants/service-order'
import useIsGenericParamActive from '_/hooks/use-is-generic-param-active'

import { useDialog } from '_/hooks/use-dialog'

const EMPTY_ITEM = { name: null, value: null }
const ROUNDS_OPTIONS = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
]

const INITIAL_STATE = { tradesman: [EMPTY_ITEM, EMPTY_ITEM, EMPTY_ITEM, EMPTY_ITEM] }

const AddProviderQueueModal = ({ handleClose, updateHistoryLog }) => {
  const dispatch = useDispatch()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const providersList = useSelector(providersResultWithoutPaginationSelector)
  const providerSuggestionsList = useSelector(getProviderSuggestionsListSelector)
  const isCategoriesOptionsLoading = useSelector(getProviderSuggestionsListSelectorLoading)
  const TOAST_INITIAL_STATE = { isOpen: false, message: '', error: '' }
  const [toast, setToast] = useState(TOAST_INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const [rounds, setRounds] = useState([EMPTY_ITEM])
  const suggestedProviders = useSelector(providerSuggestionsSelector)
  const distributionActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DISTRIBUTION,
  })
  const [selectFilter, setSelectFilter] = useState('full')
  const { showDialog, closeDialog } = useDialog()

  const providerSuggestionsListOptions = useMemo(() => {
    if (selectFilter === 'full') {
      const defaultSuggestionsRounds = providerSuggestionsList?.map((item, index) => {
        return { name: `rounds.${index}`, value: item.round }
      })
      setRounds(defaultSuggestionsRounds)

      return {
        tradesman: providerSuggestionsList?.map((item, index) => {
          return { name: `tradesman.${index}`, value: item }
        }),
        rounds: defaultSuggestionsRounds,
      }
    }

    if (selectFilter === 'category') {
      return {
        tradesman: providersList
          .filter(provider => provider?.mainServices.some(item => item.toJS().parent === null))
          .map((item, index) => ({ name: `tradesman.${index}`, value: item })),
      }
    }

    if (selectFilter === 'subcategory') {
      return {
        tradesman: providersList
          .filter(provider => provider?.mainServices.some(item => item.toJS().parent !== null))
          .map((item, index) => ({ name: `tradesman.${index}`, value: item })),
      }
    }

    return { tradesman: {} }
  }, [providerSuggestionsList, selectFilter])

  const { control, getValues, watch, handleSubmit, reset, ...methods } = useForm({
    defaultValues:
      providerSuggestionsListOptions?.tradesman?.length > 0
        ? providerSuggestionsListOptions
        : INITIAL_STATE,
  })

  const {
    fields: tradesmanFields,
    append: addTradesman,
    remove: removeTradesman,
  } = useFieldArray({
    control,
    name: 'tradesman',
  })

  const poolAvailable = useMemo(() => {
    return !!serviceOrder?.runPool
  }, [serviceOrder])

  const styles = useStyles({ poolAvailable, multipleTradesman: tradesmanFields.length > 1 })

  const addRounds = () => {
    setRounds(prev => [...prev, EMPTY_ITEM])
  }

  const removeRounds = index => {
    const newRounds = rounds.filter((_, i) => i !== index)
    setRounds(newRounds)
  }

  const handleAppendItem = useCallback(() => {
    addTradesman(EMPTY_ITEM)
    addRounds()
  }, [])

  const handleRemoveItem = useCallback(
    event => {
      const { index } = event.currentTarget.dataset
      removeTradesman(index)
      removeRounds(index)
    },
    [removeTradesman, removeRounds]
  )

  const canRemoveItem = useMemo(() => tradesmanFields.length > 1, [tradesmanFields])

  const handleCloseToast = useCallback(() => setToast(TOAST_INITIAL_STATE), [])

  const providerOptions = useMemo(() => {
    if (selectFilter === 'full') {
      return providersList?.map(item => ({
        value: item,
        name: item.name,
        label: item.name,
      }))
    }

    if (selectFilter === 'category') {
      return providersList
        .filter(provider => provider?.mainServices.some(item => item.toJS().parent === null))
        .map(item => ({
          value: item,
          name: item.name,
          label: item.name,
        }))
    }

    if (selectFilter === 'subcategory') {
      return providersList
        .filter(provider => provider?.mainServices.some(item => item.toJS().parent !== null))
        .map(item => ({
          value: item,
          name: item.name,
          label: item.name,
        }))
    }

    return []
  }, [providersList, selectFilter])

  const getOptionLabel = useCallback(
    option => {
      return (
        providerOptions?.find(opt => opt?.value?.tradesmanId === option?.value?.tradesmanId)
          ?.label || ''
      )
    },
    [providerOptions, selectFilter]
  )

  const getOptionSelected = useCallback(
    (option, currentValue) => {
      return Number(option?.value?.tradesmanId) === Number(currentValue?.value?.tradesmanId)
    },
    [selectFilter]
  )

  const onInputChange = useCallback(
    (inputLabel, name, onChange, index) => {
      const isValidOption = providerOptions.find(option => option.name === inputLabel) || null

      if (isValidOption) {
        const suggestedProvider = providerSuggestionsListOptions?.tradesman?.find(
          item => item.name === name
        )?.value
        const roundName = rounds?.[index]?.name
        const roundNameIndex = roundName?.split('.')?.[1]
        const changedRoundIndex = roundNameIndex !== String(index)

        if (suggestedProvider && Object.hasOwn(suggestedProvider, 'round')) {
          if (changedRoundIndex) {
            setRounds(prevState => {
              const newRounds = [...prevState]
              newRounds[index] = { ...prevState[index], name: `rounds.${index}` }
              return newRounds
            })
          }
        }

        onChange({ name, value: isValidOption.value })
      }
      if (inputLabel === '') {
        setRounds(prevState => {
          const newRounds = [...prevState]
          newRounds[index] = { name: `rounds.${index}`, value: '' }
          return newRounds
        })
        onChange({ name: null, value: EMPTY_ITEM })
      }
    },
    [providerOptions, providerSuggestionsListOptions, rounds]
  )

  const handleRoundsChange = (roundItem, index) => {
    const { target } = roundItem
    setRounds(prevState => {
      const newRounds = [...prevState]
      newRounds[index] = { name: `rounds.${index}`, value: target.value }
      return newRounds
    })
  }

  const renderSelect = useCallback(
    (item, index) => (
      <>
        <Grid item className={styles.fullWidth} display="flex" gap="1rem">
          <div style={{ flex: 1 }}>
            <Typography className={styles.label}>Selecione o prestador</Typography>
            <Controller
              control={control}
              name={`tradesman.${index}`}
              render={({ onChange, name, ...fieldProps }) => {
                return (
                  <Autocomplete
                    {...fieldProps}
                    id={name}
                    options={providerOptions}
                    onChange={(event, newValue) => {
                      return onChange({ name, value: newValue.value })
                    }}
                    onInputChange={(event, newInputValue) => {
                      return onInputChange(newInputValue, name, onChange, index)
                    }}
                    multiple={false}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={getOptionSelected}
                    disableClearable
                    renderInput={params => <TextField {...params} />}
                  />
                )
              }}
            />
          </div>
          {poolAvailable && (
            <CommonSelect
              name="rounds"
              label="Rodada"
              defaultValue={rounds[index]?.value || 0}
              value={rounds[index]?.value || 0}
              onChange={roundItem => handleRoundsChange(roundItem, index)}
              options={ROUNDS_OPTIONS}
              labelClassName={styles.customSelectLabel}
              selectStyles={styles.customSelect}
            />
          )}
        </Grid>
        {canRemoveItem && (
          <Grid item classes={{ item: styles.deleteButtonContainer }}>
            <IconButton
              variant="ghost"
              onClick={handleRemoveItem}
              data-index={index}
              className={styles.deleteButton}
            >
              <MinusCircleIcon color={Theme.Colors.Red.Base} />
            </IconButton>
          </Grid>
        )}
      </>
    ),
    [
      canRemoveItem,
      control,
      handleRemoveItem,
      providerOptions,
      styles.deleteButton,
      styles.deleteButtonContainer,
      styles.fullWidth,
      getOptionLabel,
      getOptionSelected,
      providerSuggestionsListOptions,
      poolAvailable,
      rounds,
    ]
  )

  const handleSelect = useCallback(
    value => {
      setSelectFilter(value)
      updateList()
    },
    [selectFilter]
  )

  const updateList = useCallback(
    (startIndex, endIndex) => {
      if (startIndex === endIndex) return

      const { tradesman: tradesmanValue, runPool } = getValues()
      const reorderedTradesman = reorder(tradesmanValue, startIndex, endIndex)
      const reorderedRounds = reorder(rounds, startIndex, endIndex)

      reset(
        { tradesman: reorderedTradesman, runPool },
        {
          keepErrors: true,
          keepDirty: true,
          keepTouched: true,
          keepSubmitCount: true,
        }
      )
      setRounds(reorderedRounds)
    },
    [getValues, reset, rounds]
  )

  const convertToSlug = useCallback(
    text =>
      text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replaceAll(' ', '_'),
    []
  )

  const getTradesmanIncludesCurrentAgencyInBlockedAgencies = tradesmanIdsList => {
    const currentAgencyId = serviceOrder?.agency?.get('id')

    for (const tradesmanId of tradesmanIdsList) {
      const tradesman = providersList.find(provider => provider?.id === tradesmanId)

      if (tradesman?.mainBlockedAgencies?.includes(currentAgencyId)) {
        return tradesman.name
      }
    }

    return undefined
  }

  const handleSubmitFunction = useCallback(
    async data => {
      setIsLoading(true)
      const candidates = data.tradesman.filter(field => field.name).map(field => field.value.id)

      const candidateThatIncludesCurrentAgencyAsBlocked =
        getTradesmanIncludesCurrentAgencyInBlockedAgencies(candidates)

      if (candidateThatIncludesCurrentAgencyAsBlocked) {
        setIsLoading(false)
        setToast(prevState => ({
          ...prevState,
          isOpen: true,
          error: `O prestador ${candidateThatIncludesCurrentAgencyAsBlocked} não pode atender a intermediária do chamado, conforme configurado em seu cadastro. Para prosseguir, selecione outro prestador.`,
        }))
      } else {
        const roundsValues = rounds?.map(round => round?.value).filter(round => round !== '')

        await dispatch(
          createTradesmanSequence(serviceOrder.id, {
            isActive: true,
            serviceOrder: serviceOrder.id,
            candidates,
            suggestedProviders,
            runPool: data.runPool,
            rounds: roundsValues,
          })
        )
          .then(() => {
            updateHistoryLog()
            dispatch(getServiceOrder(serviceOrder?.id))
            handleClose()
            setIsLoading(false)
          })
          .catch(error => {
            setToast(prevState => ({
              ...prevState,
              isOpen: true,
              error: error[0],
            }))
            setIsLoading(false)
          })
        setIsLoading(false)
      }
    },
    [serviceOrder.id, handleClose, suggestedProviders, rounds]
  )

  const handleSave = useCallback(() => {
    if (serviceOrder?.isVipClient) {
      showDialog({
        type: 'info',
        subject: 'Você tem certeza que deseja fazer o pool para o cliente VIP?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          handleSubmit(handleSubmitFunction)()
          closeDialog()
        },
        onCancel: () => {
          closeDialog()
        },
      })
      return
    }

    handleSubmit(handleSubmitFunction)()
  }, [serviceOrder?.isVipClient, rounds])

  const disableSaveButton = useMemo(
    () =>
      isLoading ||
      getValues()?.tradesman?.every(field => !field?.name) ||
      !Object.keys(getValues()).length,
    [isLoading, watch()]
  )

  const defaultFilters = useMemo(() => {
    return serviceOrder
      ? {
          ...(serviceOrder.getIn(['property', 'city']) && {
            cities: convertToSlug(serviceOrder.getIn(['property', 'city'])),
          }),
          ...(serviceOrder.category?.first?.()?.get('id') && {
            mainServices: serviceOrder.category?.first?.()?.get('id'),
          }),
          registrationStatus: REGISTER_STATUS.COMPLETE,
          serviceOrder: serviceOrder.id,
          referaSuggestion: false,
        }
      : {}
  }, [serviceOrder])

  const fetchProviders = useCallback(() => {
    if (!distributionActive) {
      dispatch(
        getGenericParameters({
          name: GENERIC_PARAMETERS.DISTRIBUTION,
        })
      ).then(data => {
        if (!data?.[0]?.active) {
          dispatch(getProvidersWithoutPagination(defaultFilters))
        }
      })
    }
  }, [dispatch])

  useEffect(() => {
    !distributionActive && fetchProviders()
  }, [fetchProviders, dispatch])

  const listProps = useMemo(
    () => ({
      item: true,
      xs: 12,
      classes: { item: styles.fieldList },
      style: { gap: '2.4rem' },
    }),
    [styles.fieldList]
  )

  const itemProps = useMemo(() => ({
    item: true,
    xs: 12,
    columnSpacing: '24',
    wrap: 'nowrap',
  }))

  const customOnChange = useCallback(
    (event, callback) => {
      setIsLoading(true)

      dispatch(
        getProvidersWithoutPagination({
          ...defaultFilters,
          referaSuggestion: event.target.checked,
        })
      )
        .then(() => {
          setIsLoading(false)
          callback()
        })
        .catch(() => {
          setIsLoading(false)
          callback()
        })
    },
    [dispatch, defaultFilters]
  )

  if (isCategoriesOptionsLoading) {
    return <Loading />
  }

  return (
    <>
      <form id="add-providers-queue">
        <Modal.Root open onClose={handleClose} disableEnforceFocus>
          <Modal.TitleModal>
            <Typography className={styles.title}>Selecionar prestador</Typography>
          </Modal.TitleModal>
          <Modal.Content className={styles.modalContent}>
            <Grid item xs={12}>
              {!serviceOrder?.isReferaService &&
                serviceOrder?.agency?.get('contractType') === 'SAAS' && (
                  <ControlledSwitch
                    name="referaSuggestions"
                    label="Mostrar sugestões da Refera"
                    control={control}
                    className={styles.showReferaSuggestions}
                    customOnChange={customOnChange}
                    defaultValue={false}
                  />
                )}
              <Typography>
                Selecione os prestadores que vão participar da sequência de aceite desse chamado,
                por ordem de prioridade.{' '}
                {/* <Link href="/#" title="Sobre sequenciamento de prestador" underline="always">
                  Clique aqui para saber mais
                </Link> */}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <FormControl className={styles.selectWrapper}>
                <Typography id="providerLabel" className={styles.selectLabel}>
                  Filtro
                </Typography>
                <Select
                  id="providerFilter"
                  labelId="providerLabel"
                  className={styles.select}
                  value={selectFilter}
                  defaultValue="full"
                  onChange={e => handleSelect(e.target.value)}
                >
                  <MenuItem key="1" value="full">
                    Completo
                  </MenuItem>
                  <MenuItem key="2" value="subcategory">
                    Subcategorias
                  </MenuItem>
                  <MenuItem key="3" value="category">
                    Só categorias
                  </MenuItem>
                </Select>
              </FormControl>

              {poolAvailable && (
                <ControlledSwitch
                  name="runPool"
                  label="Executar pool"
                  control={control}
                  className={styles.runPool}
                  defaultValue
                />
              )}
            </Grid>

            <FormProvider {...{ control, getValues, watch, handleSubmit, reset, ...methods }}>
              <DragAndDrop
                id="add-provider-queue-modal"
                list={tradesmanFields}
                renderItem={tradesmanFields?.length > 0 ? renderSelect : null}
                updateList={updateList}
                listProps={listProps}
                itemProps={itemProps}
              />
            </FormProvider>

            <Grid item xs={12} className={styles.addProviderContainer}>
              <Button
                variant="ghost"
                startIcon={<AddCircleIcon color={Theme.Colors.Primary.Base} />}
                onClick={handleAppendItem}
              >
                Adicionar outro prestador
              </Button>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Modal.ButtonRed onClick={handleClose}>Cancelar</Modal.ButtonRed>
            <Modal.ButtonFullBlue
              form="add-providers-queue"
              onClick={handleSave}
              disabled={disableSaveButton}
            >
              Salvar
            </Modal.ButtonFullBlue>
          </Modal.Actions>
        </Modal.Root>
      </form>

      {toast.error && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity={toast.error && 'error'} title={toast.error} />
        </Toast>
      )}
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
    </>
  )
}

AddProviderQueueModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  // isOpen: PropTypes.bool.isRequired,
}

export default React.memo(AddProviderQueueModal)
